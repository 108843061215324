/* @tailwind base;
@tailwind components;
@tailwind utilities; */
body{
    overflow:  hidden !important;
}
main{
    overflow: hidden !important;
}

.cs-app{
    overflow: hidden !important;
    width: 100%;
    height: calc(100vh - 56px) !important;
    padding: 20px 10px 0px 10px;
}

/* without api error notifications (normal) */
.cs-app main[role='main-route']{
    padding: 20px 0px 20px 0px !important; 
    width: 100%;
    height: calc(100vh - 78px) !important ; 
    overflow: auto !important;
}

/* with api error notifications */
.cs-app main[role='main-route'].has-notification{
    padding: 20px 0px 20px 0px !important; 
    width: 100%;
    height: calc(100vh - 134px) !important ; 
    overflow: auto !important;
}

.body__menu-open > #app > main[role='main'] > main.cs-app{
    width: calc(100vw - 256px) !important;
}

.container-layout {
    -webkit-box-flex: 1;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
}

.scrolleable {
    flex: 1 1 0px;
    /* overflow-x: scroll !important; */
    /* position: relative; */
    z-index: 0;
    /* margin: var(--margin-for-page-content); */
}

.container {
    width: 100%;
}